import React from "react";
import { IBanner } from "./props";
import "./styles.scss";

const Banner = ({
  bgImg,
  children,
  className,
  classNameSection,
  bannerRef,
  gradiente,
  onClick,
}: IBanner) => {
  return (
    <section
      className={`section__banner ${classNameSection ?? ''}`}
      // style={{
      //   backgroundImage: gradiente
      //     ? `linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.6)), url(${bgImg})`
      //     : `url(${bgImg})`,
      // }}
      ref={bannerRef}
      onClick={onClick}
    >
      <img
        src={bgImg}
        className="section__banner-image"
        style={{
          filter: gradiente ? "brightness(85%)" : "none",
        }}
        loading="lazy"
      />
      {gradiente && <div className="section__banner-overlay" />}
      <div className={`section__banner-content ${className}`}>
        {children}
      </div>
    </section>
  );
};

export default Banner;
