import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useAppSelector } from "hooks/useAppSelector";
import { toast } from "sonner";
import { Text } from "components/atoms";
import { REACT_APP_ENDPOINT_CA } from "typings/env";
import { getUtils } from "actions/utils/services";
import { getBestPrices } from "actions/best-moments/services";
import { BannerSlider } from "components/organisms";
import "./styles.scss";
import { dataBanner } from "./data";
import useRecaptcha from "hooks/useRecaptcha";
import { logSentryErrorResponse, updateMetadata } from "utils/function";
import lgZoom from "lightgallery/plugins/zoom";
import LightGallery from "lightgallery/react";
import { useTranslation } from "react-i18next";
import * as Sentry from '@sentry/react';
import { useNavigate } from "react-router-dom";

interface FormData {
  nombres: string;
  apellidos: string;
  tipo_documento_id: string;
  documento: string;
  email: string;
  htl_id: string;
  forma_participacion: string;
  publicidad_promociones: boolean;
  condiciones_uso_imagen: boolean;
  terminos_condiciones: boolean;
  imagenes: FileList | string;
}

const defaultValues: FormData = {
  nombres: "",
  apellidos: "",
  tipo_documento_id: "",
  documento: "",
  email: "",
  htl_id: "",
  forma_participacion: "Publicación de foto o video en redes sociales",
  publicidad_promociones: false,
  condiciones_uso_imagen: false,
  terminos_condiciones: false,
  imagenes: "",
};

const MomentosCasaAndina = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { getRecaptchaToken } = useRecaptcha();

  const { language, hoteles, tipo_documentos, imagenesMejoresMomentos } = useAppSelector((state) => ({
    language: state.config.language,
    hoteles: state.language.dataUtils?.hoteles || [],
    tipo_documentos: state.language.dataUtils?.tipo_documentos || [],
    imagenesMejoresMomentos: state.language.dataBestPrices?.imagenes_destacadas || [],
  }));

  const [isLoading, setIsLoading] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm<FormData>({
    mode: "onChange",
    defaultValues,
  });

  useEffect(() => {
    getUtils({ language, navigate });
    getBestPrices({ language, navigate });
    window.scrollTo(0, 0);
  }, [language]);

  useEffect(() => {
    updateMetadata({
        title: 'Momentos Casa Andina',
        description: 'Gana 1 año de noches gratis | Comparte tus #MomentosCasaAndina',
        canonicalUrl: 'https://www.casa-andina.com/es/momentos-casa-andina',
    })
  }, []);

  const validateImages = (files: FileList | string) => {
    if (!files || files.length === 0) {
      return t("forms.requiredField");
    }
    if (files.length > 10) {
      return "No puede subir más de 10 imágenes";
    }
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if(typeof file === 'string') {
        return "Archivo no válido";
      }
      if (!['image/jpeg', 'image/jpg', 'image/png'].includes(file.type.toLowerCase())) {
        return "Solo se permiten archivos jpg, jpeg o png";
      }
      if (file.size > 30 * 1024 * 1024) { // 30MB
        return "Cada imagen no puede exceder los 30MB";
      }
    }
    return true;
  };

  const onSubmit = async (data: FormData) => {
    if (!isValid) return;

    const token = await getRecaptchaToken();
    if (!token) {
      toast.error("Error al obtener el token de reCAPTCHA", {
        position: "top-right",
      });
      return;
    }

    try {
      setIsLoading(true);
      const formData = new FormData();
      const { htl_id, publicidad_promociones, condiciones_uso_imagen, terminos_condiciones, ...rest } = data;

      const dataToSend = {
        ...rest,
        htl_id: parseInt(htl_id),
        publicidad_promociones: publicidad_promociones ? 1 : 0,
        condiciones_uso_imagen: condiciones_uso_imagen ? 1 : 0,
        terminos_condiciones: terminos_condiciones ? 1 : 0,
      };

      for (const key in dataToSend) {
        if (dataToSend.hasOwnProperty(key)) {
          const value = dataToSend[key as keyof FormData];
          if (value instanceof FileList) {
            for (let i = 0; i < value.length; i++) {
              formData.append( `${key}[]`, value[i]);
            }
          } else if (value !== undefined) {
            formData.append(key, value.toString());
          }
        }
      }

      const response = await fetch(
        `${REACT_APP_ENDPOINT_CA}/form/momentos_casa_andina`,
        {
          method: "POST",
          headers: {
            "Accept": "application/json",
            "g-recaptcha-response": token,
          },
          body: formData,
        }
      );

      if (response.ok) {
        toast.success("¡Datos enviados con éxito!", { position: "top-right" });
        reset();
      } else {
        logSentryErrorResponse(response, 'Form - Momentos Casa Andina Form');
        console.error(response);
        toast.error(t("forms.errorSendingData"), { position: "top-right" });
      }
    } catch (error) {
      Sentry.captureException(error); // Captura el error en Sentry
      console.error(error);
      toast.error(t("forms.errorSendingData"), { position: "top-right" });
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <>
      <BannerSlider data={dataBanner} />
      <div className="formmomentos">
        <div className="formmomentos__container">
            <form onSubmit={handleSubmit(onSubmit)}>
                <Text text="Comparte tus #MomentosCasaAndina" type="subtitle" style={{ textAlign: "center" }}/>
                <div style={{ marginTop: "20px" }}>
                    <h3>Puedes participar de 2 formas para el sorteo:</h3>
                    <ol>
                        <li>Publica una foto o video de tu estadía en la red social de tu preferencia con el hashtag #MomentosCasaAndina, etiquétanos y saca un pantallazo de tu post para que lo subas como evidencia de tu participación en este landing.</li>
                        <li>Deja un comentario sobre tu experiencia de estadía en TripAdvisor o Google, si reservaste a través de Booking o Expedia deja un comentario en estas plataformas y también sácale pantallazo a tu publicación para que lo subas como evidencia a nuestra web.</li>
                    </ol>

                    <br />

                    <b>Comparte tus momentos favoritos en Casa Andina</b>
                </div>

                <div className="formmomentos__container--form">
                        <Controller
                        name="nombres"
                        control={control}
                        rules={{ required: "Ingrese su nombre" }}
                        render={({ field }) => (
                            <div className="input-form">
                            <input
                                {...field}
                                type="text"
                                placeholder="Nombres*"
                            />
                            {errors.nombres && <span>{errors.nombres.message}</span>}
                            </div>
                        )}
                        />

                        <Controller
                        name="apellidos"
                        control={control}
                        rules={{ required: "Ingrese su apellido" }}
                        render={({ field }) => (
                            <div className="input-form">
                            <input
                                {...field}
                                type="text"
                                placeholder="Apellidos*"
                            />
                            {errors.apellidos && <span>{errors.apellidos.message}</span>}
                            </div>
                        )}
                        />

                      <Controller
                        name="tipo_documento_id"
                        control={control}
                        rules={{ required: "Ingrese su tipo de documento" }}
                        render={({ field }) => (
                            <div className="select-form">
                            <select {...field}>
                                <option value="">Selecciona tu documento*</option>
                                {tipo_documentos.map((data: any) => (
                                <option key={data.id} value={data.id}>
                                    {data.nombre}
                                </option>
                                ))}
                            </select>
                            {errors.tipo_documento_id && <span>{errors.tipo_documento_id.message}</span>}
                            </div>
                        )}
                        />

                        <Controller
                        name="documento"
                        control={control}
                        rules={{ required: "Ingrese su documento" }}
                        render={({ field }) => (
                            <div className="input-form">
                            <input
                                {...field}
                                type="text"
                                placeholder="Documento*"
                            />
                            {errors.documento && <span>{errors.documento.message}</span>}
                            </div>
                        )}
                        />

                        <Controller
                        name="email"
                        control={control}
                        rules={{
                            required: "Ingrese un correo electrónico",
                            pattern: {
                            value: /^\S+@\S+$/i,
                            message: "Ingrese un correo electrónico válido",
                            },
                        }}
                        render={({ field }) => (
                            <div className="input-form">
                            <input
                                {...field}
                                type="email"
                                placeholder="Correo electrónico*"
                            />
                            {errors.email && <span>{errors.email.message}</span>}
                            </div>
                        )}
                        />

                        <Controller
                        name="htl_id"
                        control={control}
                        rules={{ required: t("forms.requiredField") }}
                        render={({ field }) => (
                            <div className="select-form">
                            <select {...field}>
                                <option value="">Hotel donde me alojé*</option>
                                {hoteles.map((data: any) => (
                                <option key={data.id} value={data.id}>
                                    {data.nombre}
                                </option>
                                ))}
                            </select>
                            {errors.htl_id && <span>{errors.htl_id.message}</span>}
                            </div>
                        )}
                        />
                    </div>


                    <Controller
                        name="forma_participacion"
                        control={control}
                        rules={{ required: t("forms.requiredField") }}
                        render={({ field: { onChange, value, ...field } }) => (
                            <div className="radio__form">
                                <p style={{ marginBottom: "10px" }}>Selecciona tu forma de participación:</p>
                                <label>
                                    <input
                                    {...field}
                                    type="radio"
                                    value="Publicación de foto o video en redes sociales"
                                    checked={value === "Publicación de foto o video en redes sociales"}
                                    onChange={(e) => onChange(e.target.value)}
                                    name="group_forma_participacion"
                                    />
                                    Publicación de foto o video en redes sociales
                                </label>
                                <label>
                                    <input
                                    {...field}
                                    type="radio"
                                    value="Comentario en TripAdvisor, Google, Booking o Expedia"
                                    checked={value === "Comentario en TripAdvisor, Google, Booking o Expedia"}
                                    onChange={(e) => onChange(e.target.value)}
                                    name="group_forma_participacion"
                                    />
                                    Comentario en TripAdvisor, Google, Booking o Expedia
                                </label>
                                {errors.forma_participacion && (
                                    <span>{errors.forma_participacion.message}</span>
                                )}
                            </div>
                        )}
                    />

                    <div className="columinputs1">
                        <label>Imágenes:</label>
                        <Controller
                            name="imagenes"
                            control={control}
                            rules={{
                                required: t("forms.requiredField"),
                                validate: validateImages
                            }}
                            render={({ field }) => (
                                <div className="input-form">
                                    <input
                                        id="input_imagenes"
                                        type="file"
                                        multiple
                                        onChange={(e) => field.onChange(e.target.files)}
                                    />
                                    {errors.imagenes && <span>{errors.imagenes.message}</span>}
                                </div>
                            )}
                        />
                        <p>*Máximo 10 imágenes de 30mb en formato (jpg, jpeg o png)</p>
                    </div>


                    <div className="text-terms">
                        <Controller
                        name="publicidad_promociones"
                        control={control}
                        rules={{ required: t("forms.requiredField") }}
                        render={({ field: { onChange, value, ...field } }) => (
                            <div className="checkbox__form">
                              <label>
                                  <input
                                  {...field}
                                  type="checkbox"
                                  checked={value}
                                  onChange={(e) => onChange(e.target.checked)}
                                  />
                                  <p>He leído y acepto el envío de la publicidad y promociones y la <a href={`/${language}/politica-de-proteccion-de-datos-personales`} target='_blank' rel='noreferrer' className="enlace">política de protección de datos personales</a></p>
                              </label>
                              {errors.publicidad_promociones && (
                                  <span>{errors.publicidad_promociones.message}</span>
                              )}
                            </div>
                        )}
                        />

                        <Controller
                        name="condiciones_uso_imagen"
                        control={control}
                        rules={{ required: t("forms.requiredField") }}
                        render={({ field: { onChange, value, ...field } }) => (
                            <div className="checkbox__form">
                              <label>
                                  <input
                                  {...field}
                                  type="checkbox"
                                  checked={value}
                                  onChange={(e) => onChange(e.target.checked)}
                                  />
                                  <p>He leído y acepto <a href={`/${language}/politica-uso-imagen-concurso-UCG`} target='_blank' rel='noreferrer' className="enlace">las condiciones de uso de imagen.</a></p>
                              </label>
                              {errors.condiciones_uso_imagen && (
                                  <span>{errors.condiciones_uso_imagen.message}</span>
                              )}
                            </div>
                        )}
                        />

                        <Controller
                        name="terminos_condiciones"
                        control={control}
                        rules={{ required: t("forms.requiredField") }}
                        render={({ field: { onChange, value, ...field } }) => (
                            <div className="checkbox__form">
                              <label>
                                  <input
                                  {...field}
                                  type="checkbox"
                                  checked={value}
                                  onChange={(e) => onChange(e.target.checked)}
                                  />
                                  <p>Acepto los <a href="https://ca-webprod.s3.us-east-1.amazonaws.com/Assets/MomentosCasaAndina/BASES_LEGALES_DEL_SORTEO_Momentos_Casa_Andina.pdf" target='_blank' rel='noreferrer' className="enlace">términos y condiciones del sorteo.</a></p>
                              </label>
                              {errors.terminos_condiciones && (
                                  <span>{errors.terminos_condiciones.message}</span>
                              )}
                            </div>
                        )}
                        />
                    </div>

                <button
                    type="submit"
                    disabled={!isValid || isLoading}
                    className="submit-button"
                >
                  {isLoading ? t("forms.sending") : t("forms.send")}
                </button>
            </form>

            <div style={{  }}>
              <b>👉Aplica Términos y condiciones:</b>
              <ul>
                  <li>Solo participan en el sorteo quienes dejan sus datos y evidencia en la siguiente web.</li>
                  <li>Participan cuentas públicas y privadas enviando su evidencia en la web.</li>
                  <li>Las fotos o vídeos compartidos deben ser de los hoteles de Casa Andina y se debe de identificar fácilmente.</li>
                  <li>Participa del 01/08/2024 al 31/10/2024 y se anunciará el ganador el 8 de noviembre.</li>
                  <li>A más participaciones más opciones de ganar.</li>
              </ul>

              <br />

              <a href="https://ca-webprod.s3.us-east-1.amazonaws.com/Assets/MomentosCasaAndina/BASES_LEGALES_DEL_SORTEO_Momentos_Casa_Andina.pdf" target='_blank' rel='noreferrer' className="enlace_terminos_y_condiciones">
                  DESCARGAR TÉRMINOS Y CONDICIONES ➜
              </a>
            </div>



            <h3 style={{ margin: "20px 0", textAlign: "center" }}>¡Participa ahora y comparte tu #MomentoCasaAndina!</h3>

            <LightGallery
                plugins={[lgZoom]}
                elementClassNames="image-gallery__container--item"
                showZoomInOutIcons={true}
                mode="lg-fade"
                thumbnail={true}
            >
                {imagenesMejoresMomentos.map((item: any, index: number) => (
                    <a key={index}
                        href={item?.imagen}
                        className={`length-${index}`}
                    >
                        <img
                        src={item?.imagen}
                        title={item.nombre_imagen}
                        className="image-gallery__container--img"
                        />
                    </a>
                ))}
            </LightGallery>
        </div>
      </div>
    </>
  );
};

export default MomentosCasaAndina;
