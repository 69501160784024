import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BannerSlider, BetterPrices, Nights, Visit } from 'components/organisms'
import { useAppSelector } from 'hooks/useAppSelector'
import { getHome } from 'actions/home/services'
import SectionCards from 'components/organisms/section-cards'
import BannerTira from 'components/organisms/banners/banner'
import { useNavigate } from "react-router-dom";
import { BannerData } from 'components/organisms/banners/banner-slider'

const Home = () => {
  const { t } = useTranslation()
  const [homeBanners, setHomeBanners] = useState<BannerData[]>([])
  const navigate = useNavigate();

  const { language, coin, data } = useAppSelector(state => ({
    language: state.config.language,
    coin: state.config.coin,
    data: state.language.dataHome,
  }))

  const { banners, mejoresPrecios, paraTiData, promociones, banner_tira, razones } = useMemo(() => ({
    banners: data?.banners || [],
    banner_tira: data?.banners_tira || [],
    mejoresPrecios: data?.mejores_precios || [],
    paraTiData: data?.para_ti || [],
    promociones: data?.promociones || [],
    razones: data?.razones || []
  }), [data])

  const fetchHome = () => getHome({ language, coin, navigate });

  useEffect(() => {
    if (language && coin) {
      getHome({ language, coin, navigate })
      window.scrollTo(0, 0);
      fetchHome();
    } else {
      window.scrollTo(0, 0);
    }
  }, [language, coin])


  useEffect(() => {
    if(banners)
    {
      const newBanners = banners.map((banner: BannerData) => {
        // Banner de mejores momentos
        if (banner.id === 58) {
          return {
            ...banner,
            tipo_banner: {
              titulo: 'Imagen a la derecha y botón'
            },
            img_desktop: {
              path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/mejores-momentos-banner.webp',
              alt: 'mejores-momentos-banner',
              name: 'mejores-momentos-banner'
            },
            img_mobile: {
              path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/mejores-momentos-banner-mobile.webp',
              alt: 'mejores-momentos-banner',
              name: 'mejores-momentos-banner'
            },
            imagen_secundaria: {
              path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/mejores-momentos-banner-12.png',
              alt: 'mejores-momentos-banner-12',
              name: 'mejores-momentos-banner-12'
            },
            imagen_secundaria_2: {
              path: 'https://s3.us-east-1.amazonaws.com/ca-webprod/media/mejores-momentos-banner-estadia-vuelos-alimentacion.png',
              alt: 'mejores-momentos-banner-estadia-vuelos-alimentacion',
              name: 'mejores-momentos-banner-estadia-vuelos-alimentacion'
            },
            redireccion_boton: '/es/momentos-casa-andina',
            descripcion_boton: 'Participa aquí',
          };
        }
        return banner;
      });
      setHomeBanners(newBanners)
    }
  }, [banners])

  return (
    <>
      <BannerSlider data={homeBanners} />
      <Visit data={paraTiData} title={data?.subtitulos ? data?.subtitulos[0].subtitulo : t('home.everythingPeruTitle')} />
      <BetterPrices data={mejoresPrecios} title={data?.subtitulos ? data?.subtitulos[1].subtitulo : t('home.betterPriceTitle')} />
      <BannerTira data={banner_tira} />
      <Nights data={razones} isTitle={true} title={data?.subtitulos ? data?.subtitulos[2].subtitulo : t('home.activitiesTitle') } />
      <SectionCards data={promociones} title={data?.subtitulos ? data?.subtitulos[3].subtitulo : t('home.discountsTitle') } height={287} />
    </>
  )
}

export default Home