import { useEffect, useState } from "react";
import { Icon, IconText, Hr, Text, Button } from "components/atoms";
import { SliderHotelMobile } from "components/organisms";
import { useIntl } from "react-intl";
import { Modal } from "components/molecules";
import { messages } from "utils/lang";
import { Swiper, SwiperSlide } from "swiper/react";
import "./styles.scss";
import "swiper/css";
import "swiper/css/navigation";
import Gallery from "components/molecules/gallery";
import ModalScreen from "components/molecules/modal-screen";
import LightGallery from "lightgallery/react";
import lgZoom from "lightgallery/plugins/zoom";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import { handleTravelClickRooms } from "utils/function";
import { useAppSelector } from "hooks/useAppSelector";
import Check from "assets/images/check.webp";
import { useTranslation } from "react-i18next";

const Rooms = ({ data, sectionref, nameHotel, idTravelHotel, title }: any) => {
  const { locale } = useIntl();
  const { t } = useTranslation();
  const coin = useAppSelector((state) => state.config.coin);

  const [roomsModal, setRoomsModal] = useState(false);
  const [selectedActivityIndex, setSelectedActivityIndex] = useState<
    number | null
  >(null);

  const toggleRoomsModal = (itemIndex: number | null) => {
    setSelectedActivityIndex(itemIndex);
    setRoomsModal(!roomsModal);
  };

  const toggleRoomsModal2 = (itemIndex: number | null) => {
    setSelectedActivityIndex(itemIndex);
  };

  const selectedActivity =
    selectedActivityIndex !== null ? data[selectedActivityIndex] : null;

  const HighlightedText = ({ text, color }: any) => {
    const keywords = ["Premium", "Standard", "Select"];

    const highlightKeywords = (text: string, keywords: string[]) => {
      const regex = new RegExp(`\\b(${keywords.join("|")})\\b`, "gi");
      return text.replace(
        regex,
        (match) => `<span style="color: ${color}">${match}</span>`
      );
    };

    const formattedText = highlightKeywords(text, keywords);

    return <span dangerouslySetInnerHTML={{ __html: formattedText }} />;
  };

  const onBeforeSlide = (detail: any) => {
    const { index, prevIndex } = detail;
    console.info(index, prevIndex);
  };

  useEffect(() => {
    if (roomsModal) {
      document.body.classList.add("disable-scroll");
    } else {
      document.body.classList.remove("disable-scroll");
    }
  }, [roomsModal]);

  return (
    <div className="sectionrooms" ref={sectionref}>
      <div className="sectionrooms__container">
        <Text type="subtitle" className={"subtitle"} text={title} />
        <div className="sectionrooms__description">
          <Swiper
            slidesPerView={"auto"}
            grabCursor={true}
            navigation={true}
            className="sectionrooms__description--swiper"
          >
            {data.map((better: any, index: number) => (
              <SwiperSlide key={better.id}>
                <div
                  className="sectionrooms__description--card"
                  // onClick={() => toggleRoomsModal(index)}
                >
                  <div className="sectionrooms__description--card--slider">
                    <SliderHotelMobile
                      tipo={better.img}
                      images={better.img}
                      isTag={false}
                      height="200px"
                    />
                  </div>
                  <div className="sectionrooms__description--card--container">
                    <div className="cardtitle">
                      <p className="cardtitle__title">{better.titulo}</p>
                      <div className="cardtitle__cardservices">
                        {better.espacio && (
                          <div className="cardtitle__cardservices--card">
                            <div>
                              <Icon
                                name={"space"}
                                list="items"
                                width={16}
                                height={16}
                                color="#7E7E7E"
                              />
                            </div>
                            <div>
                              <p className={"typebed"}>
                                {better.espacio} m<sup>2</sup>
                              </p>
                            </div>
                          </div>
                        )}
                        {better.tipo_cama && (
                          <div className="cardtitle__cardservices--card">
                            <Icon
                              name={"bed"}
                              list="items"
                              width={16}
                              height={16}
                              color="#7E7E7E"
                            />
                            <div>
                              <Text
                                text={better.tipo_cama}
                                className={"typebed"}
                                type="paragraph"
                              />
                            </div>
                          </div>
                        )}
                        {better.ocupacion && (
                          <div className="cardtitle__cardservices--card">
                            <div>
                              <Icon
                                name={"user"}
                                list="items"
                                width={16}
                                height={16}
                                color="#7E7E7E"
                              />
                            </div>
                            <div>
                              <p className={"ocupation"}>{better.ocupacion}</p>
                              {/* <p className={'maxocupation'}>
                                (ocupación máxima)
                              </p> */}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {better.servicios && better.servicios.length > 0 && (
                      <div className="carddescription">
                        <Text
                          text={t("hotelCity.roomServices")}
                          className="carddescription__title"
                          type="paragraph"
                        />

                        <div className="carddescription__cardservices">
                          {better.servicios
                            .slice(0, 6)
                            .map((item: any, index: number) => (
                              <div
                                className="carddescription__cardservices--card"
                                key={index}
                              >
                                <div>
                                  <img
                                    src={item.icono?.path || Check}
                                    alt={item.icono?.alt}
                                    width={14}
                                    height={14}
                                  />
                                </div>
                                <Text
                                  text={item.titulo}
                                  type="paragraph"
                                  className="paragraph"
                                />
                              </div>
                            ))}
                        </div>
                      </div>
                    )}

                    <div
                      className="cardservicesbuttons"
                      onClick={() => toggleRoomsModal(index)}
                    >
                      <IconText
                        type="right"
                        nameIcon="right"
                        listIcon="arrows"
                        text={messages[locale].showmore}
                        w={20}
                        h={20}
                        className="cardservicesbuttons__icontext"
                      />
                      <div
                        className="cardservicesbuttons__btnnext"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTravelClickRooms(
                            idTravelHotel,
                            coin,
                            better.hbt_id_travel_click
                          );
                        }}
                      >
                        {t("hotelCity.choose")}
                      </div>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <Modal
        breadcrumb={true}
        showModal={""}
        close={""}
        title={HighlightedText({ text: nameHotel, color: "#D69C4F" })}
        route={"Habitaciones"}
        allowButtons={true}
        btninactive={toggleRoomsModal}
        btnactive={"Reservar"}
        isSubtitle={true}
        subtitleCustom={
          <>
            <div className="activity-tabs">
              {data.map((activity: any, index: number) => (
                <div
                  key={activity.id}
                  className={`activity-tab ${
                    index === selectedActivityIndex ? "active" : ""
                  }`}
                  onClick={() => toggleRoomsModal2(index)}
                >
                  <Text
                    text={activity.titulo}
                    type="paragraph"
                    className={
                      `activity-tab` === activity.id ? "active-bold" : ""
                    }
                  />
                </div>
              ))}
            </div>
          </>
        }
      >
        <div className="scrollrooms">
          {selectedActivity && (
            <div>
              <div>
                <div>
                  {selectedActivity && (
                    <div>
                      <Gallery data={selectedActivity.img} />
                    </div>
                  )}
                </div>
              </div>

              <Text
                type="paragraph"
                text={selectedActivity.titulo}
                className="scrollrooms__title"
              />

              <Text
                type="paragraph"
                html={{ __html: selectedActivity.descripcion }}
                className="scrollrooms__paragraph"
              />

              <div className="carditems">
                <div className="cardservices">
                  {selectedActivity && (
                    <>
                      {selectedActivity.espacio && (
                        <div className="cardservices__card">
                          <div>
                            <Icon
                              name={"space"}
                              list="items"
                              width={14}
                              height={14}
                              color="#7E7E7E"
                            />
                          </div>
                          <div>
                            <p className={"typebed"}>
                              Espacio de {selectedActivity.espacio} m
                              <sup>2</sup>
                            </p>
                          </div>
                        </div>
                      )}
                      {selectedActivity.tipo_cama && (
                        <div className="cardservices__card">
                          <div>
                            <Icon
                              name={"bed"}
                              list="items"
                              width={14}
                              height={14}
                              color="#7E7E7E"
                            />
                          </div>
                          <div>
                            <Text
                              text={selectedActivity.tipo_cama}
                              className={"typebed"}
                              type="paragraph"
                            />
                          </div>
                        </div>
                      )}
                      {selectedActivity.ocupacion && (
                        <div className="cardservices__card">
                          <div>
                            <Icon
                              name={"user"}
                              list="items"
                              width={14}
                              height={14}
                              color="#7E7E7E"
                            />
                          </div>
                          <div>
                            <p className={"ocupation"}>
                              {selectedActivity.ocupacion}
                            </p>
                            <p className={"maxocupation"}>(ocupación máxima)</p>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>

              {selectedActivity.servicios &&
                selectedActivity.servicios.length > 0 && (
                  <>
                    <Hr />

                    <div className="servicesitems">
                      <Text
                        text={"Servicios de la habitación"}
                        className="roomservices"
                        type="paragraph"
                      />

                      <div className="servicesitems__category">
                        {selectedActivity.servicios.map(
                          (item: any, index: number) => (
                            <div
                              className="servicesitems__category--card"
                              key={index}
                            >
                              <div>
                                <Icon
                                  name={"restaurante"}
                                  list="premium"
                                  width={14}
                                  height={14}
                                  color="#7E7E7E"
                                />
                              </div>
                              {/* <img
                                src={item.icono?.path}
                                alt={item.icono?.alt}
                                style={{ width: 20, height: 20 }}
                              /> */}
                              <Text
                                text={item.titulo}
                                type="paragraph"
                                className="type-items"
                              />
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </>
                )}
            </div>
          )}
        </div>
      </Modal>

      <ModalScreen
        showModal={roomsModal}
        close={toggleRoomsModal}
        title={
          <p>
            {HighlightedText({ text: nameHotel, color: "#D69C4F" })} /
            Habitaciones
          </p>
        }
      >
        <div className="tabactivitys">
          <Swiper
            slidesPerView={"auto"}
            grabCursor={true}
            navigation={false}
            className="tabactivitys__swiper"
          >
            {data.map((index: number) => (
              <Swiper
                key={index}
                slidesPerView={"auto"}
                grabCursor={true}
                navigation={false}
                className="tabactivitys__swiper"
              >
                {data.map((activity: any, index: number) => (
                  <SwiperSlide key={index}>
                    <div
                      key={activity.id}
                      className={`tabactivityborder ${
                        index === selectedActivityIndex ? "active" : ""
                      }`}
                      onClick={() => toggleRoomsModal2(index)}
                    >
                      <Text
                        text={activity.titulo}
                        type="paragraph"
                        className={
                          `activity-tab` === activity.id ? "active-bold" : ""
                        }
                      />
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            ))}
          </Swiper>
          {selectedActivity && (
            <div className="columnroom">
              <div>
                <div className="columnroom__header">
                  <Text
                    type="paragraph"
                    text={selectedActivity.titulo}
                    className="columnroom__title"
                  />
                  <Button
                    type="gold"
                    onClick={() =>
                      handleTravelClickRooms(
                        idTravelHotel,
                        coin,
                        selectedActivity.hbt_id_travel_click
                      )
                    }
                  >
                    Reservar
                  </Button>
                </div>
                <Text
                  type="paragraph"
                  html={{ __html: selectedActivity.descripcion }}
                  className="columnroom__description"
                />

                <Hr />

                <div className="servicesitems2">
                  <Text
                    text={"Servicios de la habitación"}
                    className="roomservices"
                    type="paragraph"
                  />

                  <div className="servicesitems2__category">
                    {selectedActivity.servicios
                      // .slice(0, showAll ? selectedActivity.servicios.length : 6)
                      .map((item: any, index: number) => (
                        <div
                          className="servicesitems2__category--card"
                          key={index}
                        >
                          <div>
                            <img
                              src={item.icono?.path || Check}
                              alt={item.icono?.alt}
                              width={14}
                              height={14}
                            />
                          </div>
                          <Text
                            text={item.titulo}
                            type="paragraph"
                            className="type-items"
                          />
                        </div>
                      ))}
                  </div>
                  {/* <div
                    className='cardservsicesbuttons'
                    onClick={handleVerMasClick}
                  >
                    <IconText
                      type='right'
                      nameIcon='right'
                      listIcon='arrows'
                      text={showAll ? 'Ver menos' : 'Ver más'}
                      w={20}
                      h={20}
                      className='btnright'
                    />
                  </div> */}
                </div>
              </div>

              <div className="parentcontainer">
                <div className="parentcontainer__container">
                  <LightGallery
                    plugins={[lgZoom]}
                    mode="lg-fade"
                    elementClassNames="parentcontainer__container--item"
                    onBeforeSlide={onBeforeSlide}
                    showZoomInOutIcons={true}
                    thumbnail={true}
                  >
                    {selectedActivity.img
                      .slice(1)
                      .concat(selectedActivity.img.slice(0, 1))
                      .map((item: any, index: number) => (
                        <a
                          key={index}
                          href={item?.path}
                          className={`length-${index}`}
                          // data-sub-html={'<p>' + item?.alt + '</p>'}
                        >
                          <img
                            src={item?.path}
                            alt=""
                            className="parentcontainer__container--img"
                          />
                        </a>
                      ))}
                  </LightGallery>
                </div>
              </div>
            </div>
          )}
        </div>
      </ModalScreen>
    </div>
  );
};

export default Rooms;
