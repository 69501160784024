import { useEffect, useState, useRef, useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Text } from "components/atoms";
import { ICustomSliderTagSection, ISliderTagSection } from "./props";
import "./styles.scss";
import Searchboxgeneral from "components/organisms/navbar/searchboxgeneral";
import { useTranslation } from "react-i18next";
import { throttle } from "lodash";

const CustomSliderTagSection = ({
  item,
  isActive,
  scrollToSection,
  handleSetActiveSection,
  activeSection,
}: ICustomSliderTagSection) => {
  const handleItemClick = () => {
    handleSetActiveSection(item.link);
    scrollToSection(item.link);
  };

  return (
    <div
      className={`tabsections  ${
        item.link === activeSection ? "active-text" : "inactive-text"
      }`}
      onClick={handleItemClick}
    >
      <Text text={item.text} className="texttab" />
    </div>
  );
};

const SliderTagSection = ({
  children,
  data,
  scrollToSection,
  tabsHotelRef,
  servicesRef,
  galleryRef,
  discountsRef,
  roomsRef,
  activitiesRef,
  restaurantCityRef,
  valuationsRef,
  nameHotel,
}: ISliderTagSection) => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState(tabsHotelRef);
  const [isScrolled, setIsScrolled] = useState(false);
  const observer = useRef<IntersectionObserver | null>(null);

  const item = [
    {
      id: 1,
      text: t("hotelCity.description"),
      link: tabsHotelRef,
      referencia: data.description,
    },
    {
      id: 2,
      text: t("hotelCity.services"),
      link: servicesRef,
      referencia: data.servicios,
    },
    {
      id: 3,
      text: t("hotelCity.gallery"),
      link: galleryRef,
      referencia: data.ambientes,
    },
    {
      id: 4,
      text: t("hotelCity.offers"),
      link: discountsRef,
      referencia: data.promociones,
    },
    {
      id: 5,
      text: t("hotelCity.rooms"),
      link: roomsRef,
      referencia: data.habitaciones,
    },
    {
      id: 6,
      text: t("hotelCity.activities"),
      link: activitiesRef,
      referencia: data.actividades,
    },
    {
      id: 7,
      text: t("hotelCity.restaurants"),
      link: restaurantCityRef,
      referencia: data.restaurantes,
    },
    {
      id: 8,
      text: t("hotelCity.reviewsText"),
      link: valuationsRef,
      referencia: data.reseñas,
    },
  ];

  const handleScroll = useCallback(
    throttle(() => {
      const scrollY = window.scrollY;
      setIsScrolled(scrollY > 380);
    }, 100),
    []
  );

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (observer.current) {
      observer.current.disconnect();
    }

    const sections = [
      { ref: tabsHotelRef, id: 1 },
      { ref: servicesRef, id: 2 },
      { ref: galleryRef, id: 3 },
      { ref: discountsRef, id: 4 },
      { ref: roomsRef, id: 5 },
      { ref: activitiesRef, id: 6 },
      { ref: restaurantCityRef, id: 7 },
      { ref: valuationsRef, id: 8 },
    ];

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const active = sections.find((section) => section.ref.current === entry.target);
            if (active && activeSection !== active.ref) {
              setActiveSection(active.ref);
            }
          }
        });
      },
      { rootMargin: "-50% 0px -50% 0px" }
    );

    sections.forEach((section) => {
      if (section.ref.current) {
        observer.current?.observe(section.ref.current);
      }
    });

    return () => {
      observer.current?.disconnect();
    };
  }, [tabsHotelRef, servicesRef, galleryRef, discountsRef, roomsRef, activitiesRef, restaurantCityRef, valuationsRef, activeSection]);

  const scrollToRef = (ref: any) => {
    const yOffset = -120;
    const y =
      ref.current.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({ top: y, behavior: "smooth" });
  };

  return (
    <section style={{ position: "relative" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          position: "absolute",
          width: "100%",
          top: "0%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 9,
          padding: "16px",
        }}
      >
        <Searchboxgeneral
          searchMobile={false}
          nameHotel={nameHotel}
          id_hotel={data.id_travel_click}
        />
      </div>

      <div className={`custom-slider-tag ${isScrolled ? "visible" : "hidden"}`}>
        <div className="container">
          <div className="containerpad">
            <Swiper
              grabCursor={true}
              slidesPerView={"auto"}
              className="custom-slider-tag-section"
            >
              {item.map(
                (item, index) =>
                  item.referencia?.length > 0 && (
                    <SwiperSlide key={index}>
                      <CustomSliderTagSection
                        item={item}
                        scrollToSection={scrollToRef.bind(this, item.link)}
                        isActive={activeSection === item.link}
                        handleSetActiveSection={setActiveSection}
                        activeSection={activeSection}
                      />
                    </SwiperSlide>
                  )
              )}
            </Swiper>
          </div>
        </div>
      </div>
      <div>{children}</div>
    </section>
  );
};

export default SliderTagSection;