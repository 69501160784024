import { useState, useEffect } from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const CountdownTimer = ({ contador, colorBg }: any) => {
  const { t } = useTranslation();

  const targetTime = new Date(contador).getTime();

  const calculateTimeRemaining = () => {
    const currentTime = new Date().getTime();
    const timeDifference = targetTime - currentTime;

    if (timeDifference <= 0) {
      return {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      };
    }

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor(
      (timeDifference % (1000 * 60 * 60)) / (1000 * 60)
    );
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  };

  const initialTimeRemaining = calculateTimeRemaining();

  const [timeRemaining, setTimeRemaining] = useState(initialTimeRemaining);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, [targetTime]);

  return (
    <div className="countdown-timer">
      <div className="countdown-display">
        <div className="countdown-display-box">
          <div className="square" style={{ backgroundColor: colorBg }}>
            <div className="shadow" />
            <span>{timeRemaining.days < 10 ? `0${timeRemaining.days}` : timeRemaining.days}</span>
          </div>
          <p>
            {timeRemaining.days === 1
              ? t("countdown.day")
              : t("countdown.days")}
          </p>
        </div>
        :
        <div className="countdown-display-box">
          <div className="square" style={{ backgroundColor: colorBg }}>
            <div className="shadow" />

            <span>
              {timeRemaining.hours < 10 ? `0${timeRemaining.hours}` : timeRemaining.hours}
            </span>
          </div>
          <p>
            {timeRemaining.hours === 1
              ? t("countdown.hour")
              : t("countdown.hours")}
          </p>
        </div>
        :
        <div className="countdown-display-box">
          <div className="square" style={{ backgroundColor: colorBg }}>
            <div className="shadow" />
            <span>
              {timeRemaining.minutes < 10 ? `0${timeRemaining.minutes}` : timeRemaining.minutes}
            </span>
          </div>
          <p>
            {timeRemaining.minutes === 1
              ? t("countdown.minute")
              : t("countdown.minutes")}
          </p>
        </div>
        :
        <div className="countdown-display-box">
          <div className="square" style={{ backgroundColor: colorBg }}>
            <div className="shadow" />
            <span>
              {timeRemaining.seconds < 10 ? `0${timeRemaining.seconds}` : timeRemaining.seconds}
            </span>
          </div>
          <p>
            {timeRemaining.seconds === 1
              ? t("countdown.second")
              : t("countdown.seconds")}
          </p>
        </div>
      </div>
    </div>
  );
};

export default CountdownTimer;
