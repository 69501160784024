import {
  BannerVideo,
  Destinations,
  Hotels,
  Nights,
  SliderItems,
  Questions,
  Explore,
  RestaurantsCards,
} from "components/organisms";
import { useEffect, useRef } from "react";
import { ScrollTopButton } from "components/molecules";
import { useNavigate, useParams } from "react-router-dom";
import { getDestinationsCity } from "actions/destinations/services";
import { useAppSelector } from "hooks/useAppSelector";
import Loader from "components/molecules/loader";
import BannersHeaderB from "components/organisms/banners/banners-headerb";
import { useTranslation } from "react-i18next";

const DestinationCity = () => {
  const { city } = useParams();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const {
    language,
    coin,
    data,
    actividades,
    hoteles,
    otrosdestinos,
    preguntas,
    restaurantes,
  } = useAppSelector((state) => ({
    language: state.config.language,
    coin: state.config.coin,
    data: state.language.data,
    actividades: state.language.data?.actividades || [],
    hoteles: state.language.data?.hoteles || [],
    otrosdestinos: state.language.data?.otros_destinos || [],
    preguntas: state.language.data?.preguntasfrecuentes || [],
    restaurantes: state.language.data?.restaurantes || [],
  }));

  const descriptionRef = useRef(null);
  const bannerRef = useRef(null);
  const hotelesRef = useRef(null);
  const restaurantRef = useRef(null);
  const preguntasRef = useRef(null);

  const fetchData = () =>
    getDestinationsCity({ language, city, coin, navigate });

  useEffect(() => {
    if (language && coin && city) {
      fetchData();
      window.scrollTo(0, 0);
    }
  }, [city, navigate, language, coin]);

  const scrollToSection = (ref: any) => {
    if (ref.current) {
      const marginTop = 70;

      const topOffset = ref.current.offsetTop - marginTop;
      window.scrollTo({ top: topOffset, behavior: "smooth" });
    }
  };

  const handleRedireccionar = () => {
    scrollToSection(hotelesRef);
  };

  return (
    <>
      {!data?.video ? (
        <BannersHeaderB
          data={data}
          type={"center"}
          onClick={handleRedireccionar}
          bannerRef={bannerRef}
          hoteles={hoteles}
        />
      ) : (
        <BannerVideo
          videoUrl={data.video?.path}
          title={data.titulo || ""}
          subtitle={`"${data.subtitulo}"` || ""}
          scrollToSection={scrollToSection}
          hotelesRef={hotelesRef}
          bannerRef={bannerRef}
          hoteles={hoteles}
        />
      )}

      <SliderItems
        scrollToSection={scrollToSection}
        descriptionRef={descriptionRef}
        hotelesRef={hotelesRef}
        restaurantRef={restaurantRef}
        preguntasRef={preguntasRef}
      />

      <Destinations
        title={
          data && data.titulo
            ? t("destinationCity.discoverOurDestination", { city: data.titulo })
            : ""
        }
        data={data}
        description={data?.descripcion ?? ""}
        altitude={`${data ? data["m.s.n.m."] : ""}`}
        temperature={data?.temperatura ?? ""}
        descriptionRef={descriptionRef}
      />

      <Nights data={actividades ?? []} />

      <Hotels
        data={hoteles ?? []}
        title={data?.titulo_seccion_hoteles ?? ""}
        isTitle
        hotelesRef={hotelesRef}
        destino={data?.titulo ?? ""}
      />

      <RestaurantsCards
        data={restaurantes ?? []}
        title={data?.titulo ? t("destinationCity.visitOurRestaurants", { city: data.titulo }) : ""}
        restaurantRef={restaurantRef}
        isReview={false}
      />

      {otrosdestinos.length > 0 && (
        <Explore
          data={otrosdestinos}
          title={t("destinationCity.exploreBeyondCity", { city: data.titulo })}
        />
      )}

      {bannerRef && (
        <ScrollTopButton
          scrollToSection={scrollToSection}
          descriptionRef={bannerRef}
        />
      )}

      {preguntas.length > 0 && (
        <Questions
          data={preguntas}
          preguntasRef={preguntasRef}
          title={t("destinationCity.frequentlyAskedQuestionsAboutCity", {
            city: data.titulo,
          })}
          lineColor={"white"}
          defaultOpen
        />
      )}
    </>
  );
};

export default DestinationCity;
